import classes from "./MealsSummary.module.css";

const MealsSummary = () => {
  return (
    <section className={classes.summary}>
      <h2>Des aliments délicieux livrés à domicile</h2>
      <p>
        Choisissez votre repas préféré parmi notre large sélection de repas
        disponibles et savourez un délicieux déjeuner ou dîner à la maison.
      </p>
      <p>
        Tous nos repas sont préparés avec des ingrédients de qualité, juste à
        temps et par des chefs expérimentés !
      </p>
    </section>
  );
};

export default MealsSummary;
