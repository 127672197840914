import { Fragment, useState } from "react";

import MealsSummary from "./MealsSummary";
import AvailableMeals from "./AvailableMeals";


const Meals = () => {
  const [newMeal, setNewMeal] = useState([]);

  const mealHandler = (mealName, mealDescription, mealPrice) => {
    setNewMeal({
      name: mealName,
      description: mealDescription,
      price: mealPrice,
      id: Math.random().toString(),
    });
  };

  return (
    <Fragment>
      <MealsSummary />
      <AvailableMeals addMeal={newMeal} />
    </Fragment>
  );
};

export default Meals;
